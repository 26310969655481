
import days2024 from "../../assets/Calendar2024.json"
import days2025 from "../../assets/Calendar2025.json"


var days = []

export default {

    state(){
        return{
            items: [],
            selectedYear: 2024,
            dayDictionary: {}
        }
    },

    methods:{
        changeyear(){
        }
    },

    actions:{
        getCalender({commit}){
            commit("setCalender", days);
        },

       
    },
    
    mutations:{

        setYear(state,year){
            state.selectedYear = year
        },

        setCalender(state, days){
            
            //let position = 1;
            //let offset = 0;
            //let index = 0;
            state.items= []
            
      /*      if( state.selectedYear == 2023){
                days = days2024
            }
            else if( state.selectedYear == 2024){
                days = days2024
            }
            else if( state.selectedYear == 2025){
                days = days2024
            }
           
            }*/

            state.items = []

            days = days2024
            
            for (let x = 0; x < days.monthitems.length; x++) { 
                let dayarray = []
                for(let y = 0; y < 31; y++ ){
                    dayarray.push(days.monthitems[x].Dayitems[y]);
                    state.dayDictionary[days.monthitems[x].Dayitems[y].id]= {x,y};
                }
                state.items.push(dayarray);  
            } 

            days = days2025
            let x = 0
            
            for (let x1 = 0; x1 < days.monthitems.length; x1++) { 
                let dayarray = []
                x = x1 + 12
                for(let y = 0; y < 31; y++ ){
                    dayarray.push(days.monthitems[x1].Dayitems[y]);
                    state.dayDictionary[days.monthitems[x1].Dayitems[y].id]= {x,y};
                }
                state.items.push(dayarray); 
                console.log("test") 
            } 

           // console.log( state.dayDictionary)

            //for (let index = 0; index < state.items.length; ++index){
            //    state.dayDictionary[state.items[index].id] = index
           //}
           
        },
        
    }
}



      