

import { useStore } from "vuex";
import { computed, ref } from "vue";
//import format from 'date-fns/format' 
//import { datetime,rrulestr } from 'rrule'
import calenderCore from "./calenderCore";
import axios from 'axios';
import ICalParser from 'ical-js-parser';


export default function iCal() {
    const store = useStore();
    const { state } = store;
    
    const calenderCore1 = calenderCore();
    const myCalendars = ref(computed(() => state.mycalendars.items));
    const items = ref(computed(() => state.calender.items));
    //const dayDictionary = ref(computed(() => state.calender.dayDictionary));
    const events = ref(computed(() => state.events.items));
    
    function removeEventsFromCalendar(calendarId){
        for (let x = 0; x < myCalendars.value.length; ++x){
            if(myCalendars.value[x].calendarId == calendarId){
                myCalendars.value[x].events =[] 
            }
        }
        let filteredEvents = events.value.filter(event => event.calendarId !== calendarId);

        // Aktualisiere das ursprüngliche Array mit den gefilterten Events
        events.value.length = 0;
        events.value.push(...filteredEvents);
        console.log("ok")
    }

    async function parseICALFile(calendarInfos) {
        removeEventsFromCalendar(calendarInfos.calendarId)
        let newUrl = 'https://nj.annucal.com/' + calendarInfos.externalCalendarLink
        //let newUrl = 'https://cors-anywhere.herokuapp.com/' + calendarInfos.externalCalendarLink ; //https://cors-anywhere.herokuapp.com/corsdemo
        
          axios(newUrl, { 
            method: 'GET',
          }).then(async response => {
            //console.log(response.data)
          const resultJSON = ICalParser.toJSON(response.data);
           //https://snyk.io/advisor/npm-package/ical-js-parser
          resultJSON.calendarInfos = {calendarInfos }

          store.dispatch("createEventOnlyInBrowser",resultJSON)

          }).then(async () =>{
             
            await store.dispatch("refreshIndexList").then(async()=>{
                // Write each event index in the events of each mycalendars
                await store.dispatch("getmydaysWithEventsFromDB", {
                    events: store.state.events.items, 
                    myCalendars: store.state.mycalendars.items, 
                    calender: store.state.calender.items}).then(()=>{
                      calenderCore1.updateCalender();
                    })
            })
            
          })
          .catch(error => {
            console.log(error)
          })

    } 

    return{
        parseICALFile,
        events,
        items
    }
}

// npm install luxon
// import { DateTime } from 'luxon';

//function convertToDateTime(dateString, timezone) {
//    return DateTime.fromISO(dateString, { zone: timezone });
//}

//start: convertToDateTime(event.start, timezone),

