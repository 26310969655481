
import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  
} from "firebase/auth";

import { addDoc, collection, doc, setDoc, updateDoc, Timestamp } from "firebase/firestore";
import calenderCore from "../../composition/calenderCore";
import { db } from "../../db";


export default {
  namespaced: true,
  
  state() {
    return {
      data: null,
      auth: {
        isProcessing: false,
        error: ""
      },
      userID: null
      
    }
  },



  data(){
    return {
      calenderCore1 : calenderCore()
    }
  },

  getters: {
    isAuthenticated(state) {
      return !!state.data;
    },
    currentUserID(state){
      return !!state.userID
    }
  },

  actions: {

    onAuthChange({dispatch, commit}, callback) {
      
      commit("setAuthIsProcessing", true);
      onAuthStateChanged(getAuth(), async (user) => {
        if (user) {
          await dispatch('getUserProfile', user);
          commit("setAuthIsProcessing", false);
          callback(user);
        } else {
          commit("setAuthIsProcessing", false);
          callback(null);
        }
      })
      
    },

    async updateProfile({commit, dispatch}, { data, onSuccess }) {
      const userRef = doc(db, "users", data.id);
      await updateDoc(userRef, data);
      commit("updateProfile", data);
      dispatch("toast/success", "Profile has been updated!", {root: true});
      onSuccess();
    },

    async getUserProfile({commit}, user) {
      
     // const docRef = doc(db, "users", user.uid);
     // const docSnap = await getDoc(docRef);
     // const userProfile = docSnap.data();
     // const useWithProfile = {
     //   id: user.uid,
     //   email: user.email,
     //   ...userProfile
      //}
      //commit("setUser", useWithProfile);
      commit("setUser", user);
    },

    async register({commit, dispatch}, {email, password, username}) {

      commit("setAuthIsProcessing", true);
      commit("setAuthError", "");

      try {
        const userCredentials = await createUserWithEmailAndPassword(getAuth(), email, password, username);
        
        await dispatch("createUserProfile", {
          id: userCredentials.user.uid,
          email,
          username
        })

      } catch(e) {
        commit("setAuthError", e.message);
        dispatch("toast/error", e.message, {root: true});
        console.log("Fehler: " + e.message)
      } finally {
        commit("setAuthIsProcessing", false);
      }
    },

    async login({commit, dispatch}, {email, password}) {
      
      commit("setAuthIsProcessing", true);
      commit("setAuthError", "");

      try {
        await signInWithEmailAndPassword(getAuth(), email, password);
        
      } catch(e) {
        commit("setAuthError", e.message);
        dispatch("toast/error", e.message, {root: true});
      } finally {
        commit("setAuthIsProcessing", false);
      }
    },

    async logout({commit}) {
     try {
      await signOut(getAuth());
      commit("setUser", null);
   
     } catch(e) {
      console.error("Error: " + e);
     }
    },

    async createUserProfile(context, {id, ...profile}) {
      
      await setDoc(doc(db, "users", id), profile)
      .then (() => {
        addDoc(collection(db, 'calendars'),{
          name:'Privat',
          description: 'Standard Kalender',
          creatorUID: id,
          creatorName: profile.username,
          color:"#FF9933",
            icon: "ph-CalendarCheck",
        }).then(async (docRef) => {
            await updateDoc(doc(db, 'calendars', docRef.id),{
            calendarId : docRef.id,
          });

          let newCalendar = {
            color: "#FF9933", 
            icon: "ph-CalendarCheck",
            name: "Privatkalender",
            description: 'Mein privater Kalender',
            visible: true,
            private: true,
            creatorUID: id,
            creatorName: profile.username,
            calendarId : docRef.id,
            createdOn: Timestamp.now(),
            editedOn: Timestamp.now(),
            subscribers: 0,
          }

          const docRef2 = await addDoc(collection(collection(db, 'users'), id, 'myCalendars'), newCalendar)
          await updateDoc(doc(collection(db, 'users'), id, 'myCalendars', docRef2.id),{docId : docRef2.id});

        

          location.reload(); 
          
        })
        
      })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    });
    }
  },
  mutations: {

    setAuthIsProcessing(state, isProcessing) {
      state.auth.isProcessing = isProcessing;
    },
    setAuthError(state, error) {
      state.auth.error = error;
    },
    setUser(state, user) {
      state.data = user;
      state.userID = user.uid;
    },
    updateProfile(state, profile) {
      state.data = {...state.data, ...profile};
    }
  }
}