
<template>
  <div>
    <img v-if="!isAuthenticated  == true"
      src="@/assets/images/annucal_icon_login.svg" 
        @mouseenter="handleShow($event)" 
        @click="toggle === false ? handleClick($event) : handleHide($event)"
        style="
        height:20px;
        cursor: pointer;
      ">
    

    <img v-if="isAuthenticated  == true"
      src="@/assets/images/annucal_icon_loggedin.svg" 
        @mouseenter="handleShow($event)" 
        @click="toggle === false ? handleClick($event) : handleHide($event)"
        style="
        height:24px;
        cursor: pointer;
      ">



    <div class="popoverPanel">
      <div class="popoverArrow" data-popper-arrow></div>

      <!--Forgot Password-->   
       <div v-if="!isAuthenticated && showForgotPassword == true" 
    
      style="
      padding-left:15px;
      padding-right: 15px;
      
      ">
   <div style="
                
                padding-right: 45px;
                padding-top:19px;
                padding-bottom:15px;
                font-size: medium;
                font-weight:700;
                color:#0b3558;
                ">
                {{ $t("Forgot password") }}
              </div>
              <div class="cointainer is-fluid has-text-centered">
        <div class="box">
        <form @submit.prevent>
          
            <div class="field">
            <input
              type="email"
              placeholder="Email"
              v-model="forgotPasswordemail"
              id="email"
              class="input is-small"
              required
            />
        </div>
          
       
            <button
              type="submit"
              @click="sendEmail"
              style="background-color: #0099FF;
              color:white"
              class="button is-block is-small is-fullwidth"
            >
            {{ $t("Reset now") }}
            </button>
          
          
        </form>
        </div>
           <p style="text-align: end">
                    <span class="has-text-grey  is-size-7 infotext">{{ $t("No account yet") }}?</span>&nbsp;
                    <span class="infotext is-size-7 has-text-weight-medium"
                    @click="openRegister">
                    {{ $t("Register now") }}
                    </span>
                  </p>
        </div>


      




<!--End Passwrot-->


      </div>
   
      <!--Abmelden-->   
      <div v-if="isAuthenticated && showForgotPassword == false" 
      class="cointainer is-fluid has-text-centered"
      style="
      padding-left:30px;
      padding-right: 30px;
      padding-top:19px;
      
      ">
      <div class="box">
        <div style="
        font-size: medium;
        font-weight:600;
      "  is-small>Angemeldet als</div> 
        <div style="
        font-size: medium;
        font-weight:400;
      " is-small>{{ user.email }}</div>

          <button
          @click="logout"
              :disabled="isProcessing"
              type="button"
              class="button is-block is-info is-small is-fullwidth"
              style="
              margin-top: 15px;
              background-color: #0099FF;
      "
            >
              Abmelden
            </button>
      </div>
    
     
      </div>

      <!--Anmelden-->   
      <div style="
        padding-left:15px;
        padding-right: 15px;">
          <div v-if="!isAuthenticated && showForgotPassword == false && showRegister==false">
            <div style="
                
                padding-right: 45px;
                padding-top:19px;
                padding-bottom:15px;
                font-size: medium;
                font-weight:700;
                color:#0b3558;
                ">
                   {{ $t("Log in") }}
              </div>
              <div class="cointainer is-fluid has-text-centered">
                <div >
                  <div class="box">
                    <form>
                      <div class="field">
                        <div class="control">
                          <input
                            v-model="form.email"
                            class="input is-small"
                            type="email"
                            placeholder="Email"
                            autofocus=""
                            autocomplete="email">
                        </div>
                      </div>
                      <div class="field">
                        <div class="control">
                          <input
                            v-model="form.password"
                            class="input is-small"
                            type="password"
                            placeholder="Passwort"
                            autocomplete="current-password">
                        </div>
                      </div>
                      <button
                        @click="login"
                        :disabled="isProcessing"
                        type="button"
                        style="background-color: #0099FF;
              color:white"
                        class="button is-block  is-small is-fullwidth">
                        {{ $t("Log in") }}
                      </button>
                    </form>
                  </div>
                  
                  <p style="text-align: end">
                    <span class="has-text-grey  is-size-7 infotext">{{ $t("No account yet") }}?</span>&nbsp;
                    <span class="infotext is-size-7 has-text-weight-medium"
                    @click="openRegister">
                    {{ $t("Register now") }}
                    </span>
                  </p>
                  <p>
                    <span class="has-text-grey is-size-7 infotext ">{{ $t("Forgot password") }}?</span>&nbsp;
                    <span class="infotext is-size-7 has-text-weight-medium"
                    @click="openForgotPassword">
                    {{ $t("Reset now") }}
                    </span>
                  </p>
                </div>
              </div>
          </div>
      </div>

      <!--Register-->
       <div style="
        padding-left:15px;
        padding-right: 15px;">
      <div v-if="!isAuthenticated && showRegister == true">
        <div style="
                
                padding-right: 45px;
                padding-top:19px;
                padding-bottom:15px;
                font-size: medium;
                font-weight:700;
                color:#0b3558;
                ">
                 {{ $t("Register now") }}
              </div>
              <div class="cointainer is-fluid has-text-centered">
        <div class="box">
          <form>
            <div class="field">
              <div class="control">
                <input
                  v-model="form.email"
                  class="input is-small"
                  type="email"
                  placeholder="Email"
                  autofocus=""
                  autocomplete="email">
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  v-model="form.username"
                  class="input is-small"
                  type="text"
                  placeholder="Username"
                >
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  v-model="form.password"
                  class="input is-small"
                  type="password"
                  placeholder="Passwort"
                  autocomplete="current-password">
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  v-model="form.passwordConfirmation"
                  class="input is-small"
                  type="password"
                  placeholder="Passwort wiederholen"
                >
              </div>
            </div>
            <button
              @click="register"
              :disabled="isProcessing"
              type="button"
              style="background-color: #0099FF;
              color:white"
              class="button is-block 
             is-small is-fullwidth">
             {{ $t("Register now") }}
            </button>
          </form>
          
          <p style="text-align: end;
          padding-top: 15px"
          >
                    <span class="has-text-grey  is-size-7 infotext">{{ $t("Already registered?") }}</span>&nbsp;
                    <span class="infotext is-size-7 has-text-weight-medium"
                    @click="openLogin">
                    {{ $t("Log in now") }}
                    </span>
                  </p>
                  
         
        </div>
        </div>
      </div>
      </div>
        
      <!--close Button-->      
      <div  
      style="
      padding: 10px;
      ">
      </div>
          <div class="modal-card-foot go-right">
            <button class="button "
            @click="handleHide($event), showForgotPassword = false"
            >{{ $t("Close") }}</button>
          </div>
      </div>
  </div>
</template>

<script>

import { defineComponent, ref, computed } from 'vue'
import { createPopper } from '@popperjs/core'
import calenderCore from "../composition/calenderCore";
import useAuth from '../composition/useAuth';
import { useStore } from "vuex";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useToast } from "vue-toastification"
const toast = useToast();

export default defineComponent({
 
  name: 'PopoverLogin',
  props: {
    placement: String
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        username: ""
      },
      message: null,
      error: null,
      emailSending: false,
      forgotPasswordemail: "",
      useAuth,
      showForgotPassword: false,
      showRegister: false
    }
  },


  methods: {

    async register() {
      
      if(this.form.password != this.form.passwordConfirmation){
        toast.error("Die Passwörter stimmen nicht überein"); 
      }
      else{
        await this.$store.dispatch("user/register", this.form)
        this.$store.state.settings.Authenticated = true
        }
        

    },

    sendEmail() {
      
        if (!this.forgotPasswordemail) {
          this.error = "Please type in a valid email address.";
          return;
        }
        this.error = null;
        this.message = null;
        this.emailSending = true;
        
          sendPasswordResetEmail(getAuth(),this.forgotPasswordemail)
          .then(() => {
            this.emailSending = false;
            //this.message = "Eine Email zum zurück setzen Deines Passworts wurde versendet";
            toast.success("Eine Email zum zurück setzen Deines Passworts wurde versendet")
          })
          .catch(error => {
            this.emailSending = false;
            if(error.message.includes ("invalid-email")){
              toast.error("Die Emailadresse scheint nicht korrekt zu sein"); 
                //this.error = "Die Emailadresse scheint nicht korrekt zu sein"
                //dispatch("toast/error", "Die Emailadresse scheint nicht korrekt zu sein", {root: true});
            }
            else{
                //this.error = "Ein Fehler ist aufgetreten. Bitte versuche es noch einmal" //error.message;
                //store.dispatch("toast/error", "Ein Fehler ist aufgetreten. Bitte versuche es noch einmal", {root: true});
                toast.error(error)
            }
            
          });
      },


    async login() {
      await this.$store.dispatch("user/login", this.form);
      if (this.isAuthenticated){
        this.$router
          .push({ path: '/' })
          .then(() => { this.$router.go() })
      }
    },

    resetVariables(){
      this.showForgotPassword = false
    }
   
  },

  watch: {
    isAuthenticated(isAuth) {
      if (isAuth) { 
        this.$router.push("/");
      }
    }
  },

  setup(props) {
    const button = ref(null);
    const popover = ref(null);
    const toggle = ref(false);
    const calenderCore1 = calenderCore();
    const store = useStore();
    const { state } = store;
    
    
    const isProcessing = computed(() => state.user.auth.isProcessing);
    const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);
    const user = computed(() => state.user.data);

    const popperInstance = computed(() => {
      return createPopper(button.value, popover.value, {
        placement: props.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ],
        strategy: 'absolute'
      })
    })

    const insertElement = (btn, tip) => {
      button.value = btn
      popover.value = tip
    }

    const handleShow = (e) => {
      if(button.value === null && popover.value === null) {
        insertElement(e.target, e.target.parentElement.querySelector('.popoverPanel'))
      }
    }

    const handleClick = (e) => {
      if(button.value === null && popover.value === null) {
        insertElement(e.target, e.target.parentElement.querySelector('.popoverPanel'))
      }
      popover.value.setAttribute('data-show', '')
      popperInstance.value.update()
      toggle.value = true
    }

    const handleHide = (e) => {
      
      if(button.value === null && popover.value === null) {
        insertElement(e.target, e.target.parentElement.querySelector('.popoverPanel'))
      }
      
      popover.value.removeAttribute('data-show');
      toggle.value = false
      
    }

    function bundeslaenderClicked(bundesLand){
      this.$store.state.settings.bundesLaender = bundesLand;
      calenderCore1.updateCalender();
    }

    function checkBoxClicked(checkBoxName){
      
      var checkBox = document.getElementById(checkBoxName);
      if (checkBox.checked == true){
        checkBox.checked = false;
        switch(checkBoxName) {
        case 'togglekw':
          this.$store.state.settings.showKalenderwochen = false;
          break;
        case 'toggleshoolholiday':
          this.$store.state.settings.showSchulferien = false;
          calenderCore1.updateCalender();
          break;
        case 'toggleholidays':
          this.$store.state.settings.showFeiertage = false;
          calenderCore1.updateCalender();
          break;
        default:
          // code block
      }
      } else {
        checkBox.checked = true;
        switch(checkBoxName) {
        case 'togglekw':
          this.$store.state.settings.showKalenderwochen = true;
          break;
        case 'toggleshoolholiday':
          this.$store.state.settings.showSchulferien = true;
          calenderCore1.updateCalender();
          break;
        case 'toggleholidays':
          this.$store.state.settings.showFeiertage = true;
          calenderCore1.updateCalender();
          break;
        default:
          // code block
      }
      }
    }

    function openForgotPassword(){
      this.showForgotPassword = true
      this.showRegister = false
    }

    function openRegister(){
      this.showRegister = true
      this.showForgotPassword = false
    }

    function openLogin(){
      this.showForgotPassword = false
      this.showRegister = false
    }

    function logout(){
      this.$store.dispatch('user/logout')
      this.$store.state.settings.introPageToShow = 1
      this.$store.state.settings.startPageOpen = true
      this.$router
      .push({ path: '/' })
      .then(() => { this.$router.go() })
    }

    function close(){
      this.showModal = false; 
    }

    return {
      toggle,
      handleShow,
      handleClick,
      handleHide,
      checkBoxClicked,
      bundeslaenderClicked,
      close,
      isAuthenticated,
      user,
      openForgotPassword,
      openRegister,
      openLogin,
      isProcessing,
      calenderCore1 : calenderCore(),
      logout
    }
  },
})
</script>

<style scoped>

.go-right{
  justify-content: flex-end;
  background-color: white;
}

.country-name{
  font-size: small;
  font-weight: 600;
}
.toggle {
  margin-top: 4px;
 
  position: relative;
}
.toggle input[type="checkbox"] {
  opacity: 0;
  position:absolute;
}
.toggle label {
  background-color: rgba(0, 0, 0, 0.3);
  border: 0px solid #BBBBBB;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1) inset;
  height: 15px;
  position: relative;
  width: 30px;
  display: block;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.toggle input:checked ~ label {
  background-color: #19A8E4;
  background-image: linear-gradient(to bottom, #0391f5 0px, #0391f5 100%);
  border: 0px solid #0391f5;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.25) inset;
}
.toggle .toggle-handle {
    background-color: #FFFFFF;
    background-image: linear-gradient(to bottom, #FFFFFF 0px, #F2F2F2 100%);
    border: 2px solid rgba(0, 0, 0, 0.3);
    border-radius: 100px 100px 100px 100px;
    height: 15px;
    position: absolute;
    -webkit-transition: -webkit-transform 0.1s ease-in-out 0s, border 0.1s ease-in-out 0s;
    transition: transform 0.1s ease-in-out 0s, border 0.1s ease-in-out 0s;
    width: 15px;
    z-index: 2;
    pointer-events: none;
}
.toggle input:checked + .toggle-handle {
    border-color: #0391f5;
    -webkit-transform: translate3d(15px, 0px, 0px);
    transform: translate3d(15px, 0px, 0px);
}
.toggle label:after {
    color: #e81616;
    
    font-weight: bold;
    line-height: 28px;
    position: absolute;
    right: 13px;
    text-shadow: 0 1px #FFFFFF;
    text-transform: uppercase;
}
.toggle input:checked ~ label:after {
    color: #FFFFFF;
    
    left: 15px;
    right: auto;
    text-shadow: 0 -1px rgba(0, 0, 0, 0.25);
}












.popoverPanel {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.4);
  border-radius: .1rem;
  font-size: .875rem;
  display: none;
}

.popoverPanel[data-show] {
  display: block;
}

.popoverHeader {
  padding: .5rem 1rem;
  margin: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0,0,0,.2);
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}

.popoverBody {
  padding: 1rem 1rem;
  color: #0b3558;
}

.popoverArrow,
.popoverArrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.popoverArrow {
  visibility: hidden;
}

.popoverArrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.popoverPanel[data-popper-placement^='top'] > .popoverArrow {
  bottom: -5px;
}

.popoverPanel[data-popper-placement^='top'] > .popoverArrow::before {
  border-bottom: 1px solid rgba(0,0,0,.5);
  border-right: 1px solid rgba(0,0,0,.5);
}

.popoverPanel[data-popper-placement^='bottom'] > .popoverArrow {
  top: -5px;
}

.popoverPanel[data-popper-placement^='bottom'] > .popoverArrow::before {
  border-top: 1px solid rgba(0,0,0,.5);
  border-left: 1px solid rgba(0,0,0,.5);
}

.popoverPanel[data-popper-placement^='left'] > .popoverArrow {
  right: -5px;
}

.popoverPanel[data-popper-placement^='left'] > .popoverArrow::before {
  border-top: 1px solid rgba(0,0,0,.5);
  border-right: 1px solid rgba(0,0,0,.5);
}

.popoverPanel[data-popper-placement^='right'] > .popoverArrow {
  left: -5px;
}

.popoverPanel[data-popper-placement^='right'] > .popoverArrow::before {
  border-bottom: 1px solid rgba(0,0,0,.5);
  border-left: 1px solid rgba(0,0,0,.5);
}
</style>
