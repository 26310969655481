
  export default {
  
      state(){
          return{
            settingsPageIsVisible: false,
            showFeiertage: false,
            showSchulferien: false,
            showKalenderwochen: false,
            bundesLaender: '',
            FirstDayOfEventSelected: false,
            LastDayOfEventSelected: false,
            mouseDownEventStartDate: false,
            mouseDownEventEndDate: false,
            addDayOnEvent: false,
            ismousedown: false,
            selectedEventIDtoChangeDate: "",
            selectedEventIndexToChange: -1,
            slectedDayId: "",
            SlotHeader: "",
            ShowDeleteButton: false,
            SlotMode: "",
            SlotColor: "",
            eventName: "",
            eventColor: "#eeeeee", 
            currentEventId: "",
            currentEventIdDB: "",
            currentSelectedCalendar: null,
            currentCalendarName: "Calendar",
            currentCalendarColor: "#ffddrr",
            currentCalendarIcon: "default",
            currentCalendarId: "-1",
            currentVisibleCalendars: [],
            BackColorWeekend: "#d9d9d9",
            BackColorNormalDay: "#ffffff",
            isdragging: false,
            selectedCursor: 'default',
            selectedYear: 2024,
            ctrlPressed: false,
            FixedWithMousUp: false,
            fetchedDataFromDb: false,
            userID: "not defined",
            userName: "not defined",
            Authenticated: false,
            currentdatefrom: "",
            currentdateto: "",
            currentdatefromJson: "",
            currentdatetoJson: "",
            eventNeedsSaving: "no",
            popOverCalenderOpen: false,
            startPageOpen: true,
            NewCalendarPageOpen: false,
            introPageToShow: 6,
            currentRealDate:"",
            currentDraggingEventIndex: -1,
            screenWidth: 0,
            canvasOffScreenVisible: false,
            hamburgerMenueVisible: false,
          }
      },
  }
  
   