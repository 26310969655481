
<template>
    <div >
      <img src="@/assets/images/annucal_icon_help.svg" 
        @mouseenter="handleShow($event)" 
        @click="toggle === false ? handleClick($event) : handleHide($event)"
        style="
        height:20px;
        cursor: pointer;
      ">
  
      <div class="popoverPanel">
        <div class="popoverArrow" data-popper-arrow></div>
     
        <div style="
        padding-left:25px;
        padding-right: 95px;
        padding-top:19px;
        padding-bottom:5px;
        color:#0b3558;
        ">
        <div style="
          
          font-size: medium;
          font-weight:700;
        ">
          Info zur Bedienung
        </div>
        
        </div>
  
        <div style="
        padding-left:25px;
        padding-right:25px;
        padding-bottom: 25px;
        color:#0b3558;
        ">
        <il class="li-left" style="list-style: none;">
            <li><span class="info-left" style="margin-top:35px">Neues Ereignis: </span> Mausklick auf Datum</li>
            <li><span class="info-left" style="margin-top:35px">Ereignis bearbeiten: </span>Mausklick auf Ereignis</li>
            <li><span class="info-left" style="margin-top:35px">Ereignis verkürzen: </span>Maus gedrückt halten & ziehen</li>
            <li><span class="info-left" style="margin-top:35px">Ereignis verlängern: </span>Maus gedrückt halten & ziehen</li>
            <li><span class="info-left" style="margin-top:35px">Ereignis verschieben: </span>Maus gedrückt halten & verschieben (Drag&Drop)</li>
          <li><span class="info-left" style="margin-top:35px">Ereignis mit weniger als 3 Tagen verschieben: </span>Taste D & Maus gedrückt halten & verschieben (Drag&Drop)</li>
            <li><span class="info-left" style="margin-top:35px">Tagesinfo einblenden: </span>Maus über Datum (Mouse over)</li>
            
            <li><span class="info-left">Weitere Jahreskalender anzeigen: </span>Klick auf Jahreszahl</li>
            <li><span class="info-left">Ein- und ausloggen: </span>Klick auf  
              <img src="@/assets/images/annucal_icon_login.svg" style="height:15px; margin-top:0px;">
            </li>

  
        </il>
          </div>
  
          <div class="modal-card-foot go-right">
       
        <button class="button "
        @click="handleHide($event)"
        >{{ $t("Close") }}</button>
      </div>
      
      </div>
    </div>
  </template>
  
  <script>
  
  import { defineComponent, ref, computed } from 'vue'
  import { createPopper } from '@popperjs/core'
  import calenderCore from "../composition/calenderCore";
  
  export default defineComponent({
    
    name: 'PopoverSettings',
    props: {
      placement: String
    },
  
    setup(props) {
      const button = ref(null);
      const popover = ref(null);
      const toggle = ref(false);
      const calenderCore1 = calenderCore();
      
      const popperInstance = computed(() => {
        return createPopper(button.value, popover.value, {
          placement: props.placement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ],
          strategy: 'absolute'
        })
      })
  
      const insertElement = (btn, tip) => {
        button.value = btn
        popover.value = tip
      }
  
      const handleShow = (e) => {
        if(button.value === null && popover.value === null) {
          insertElement(e.target, e.target.parentElement.querySelector('.popoverPanel'))
        }
      }
  
      const handleClick = (e) => {
        if(button.value === null && popover.value === null) {
          insertElement(e.target, e.target.parentElement.querySelector('.popoverPanel'))
        }
        popover.value.setAttribute('data-show', '')
        popperInstance.value.update()
        toggle.value = true
      }
  
      const handleHide = (e) => {
        if(button.value === null && popover.value === null) {
          insertElement(e.target, e.target.parentElement.querySelector('.popoverPanel'))
        }
        popover.value.removeAttribute('data-show');
        toggle.value = false
      }
  
      function bundeslaenderClicked(bundesLand){
        this.$store.state.settings.bundesLaender = bundesLand;
        calenderCore1.updateCalender();
      }
  
      function checkBoxClicked(checkBoxName){
        
        var checkBox = document.getElementById(checkBoxName);
        if (checkBox.checked == true){
          checkBox.checked = false;
          switch(checkBoxName) {
          case 'togglekw':
            this.$store.state.settings.showKalenderwochen = false;
            break;
          case 'toggleshoolholiday':
            this.$store.state.settings.showSchulferien = false;
            calenderCore1.updateCalender();
            break;
          case 'toggleholidays':
            this.$store.state.settings.showFeiertage = false;
            calenderCore1.updateCalender();
            break;
          default:
            // code block
        }
        } else {
          checkBox.checked = true;
          switch(checkBoxName) {
          case 'togglekw':
            this.$store.state.settings.showKalenderwochen = true;
            break;
          case 'toggleshoolholiday':
            this.$store.state.settings.showSchulferien = true;
            calenderCore1.updateCalender();
            break;
          case 'toggleholidays':
            this.$store.state.settings.showFeiertage = true;
            calenderCore1.updateCalender();
            break;
          default:
            // code block
        }
        }
      }
  
      function close(){
        this.showModal = false; 
      }
  
      return {
        toggle,
        handleShow,
        handleClick,
        handleHide,
        checkBoxClicked,
        bundeslaenderClicked,
        close
      }
    }
  })
  </script>
  
  <style scoped>

  .li-left{
    padding-left: 25px;
  }
  
  .info-left{
    font-weight: 600;
    
  }
  .go-right{
    justify-content: flex-end;
    background-color: white;
  }
  
  .country-name{
    font-size: small;
    font-weight: 600;
  }
  .toggle {
    margin-top: 4px;
   
    position: relative;
  }
  .toggle input[type="checkbox"] {
    opacity: 0;
    position:absolute;
  }
  .toggle label {
    background-color: rgba(0, 0, 0, 0.3);
    border: 0px solid #BBBBBB;
    border-radius: 20px 20px 20px 20px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1) inset;
    height: 15px;
    position: relative;
    width: 30px;
    display: block;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  .toggle input:checked ~ label {
    background-color: #19A8E4;
    background-image: linear-gradient(to bottom, #0391f5 0px, #0391f5 100%);
    border: 0px solid #0391f5;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.25) inset;
  }

  .toggle .toggle-handle {
      background-color: #FFFFFF;
      background-image: linear-gradient(to bottom, #FFFFFF 0px, #F2F2F2 100%);
      border: 2px solid rgba(0, 0, 0, 0.3);
      border-radius: 100px 100px 100px 100px;
      height: 15px;
      position: absolute;
      -webkit-transition: -webkit-transform 0.1s ease-in-out 0s, border 0.1s ease-in-out 0s;
      transition: transform 0.1s ease-in-out 0s, border 0.1s ease-in-out 0s;
      width: 15px;
      z-index: 2;
      pointer-events: none;
  }
  .toggle input:checked + .toggle-handle {
      border-color: #0391f5;
      -webkit-transform: translate3d(15px, 0px, 0px);
      transform: translate3d(15px, 0px, 0px);
  }
  .toggle label:after {
      color: #e81616;
      
      font-weight: bold;
      line-height: 28px;
      position: absolute;
      right: 13px;
      text-shadow: 0 1px #FFFFFF;
      text-transform: uppercase;
  }
  .toggle input:checked ~ label:after {
      color: #FFFFFF;
      
      left: 15px;
      right: auto;
      text-shadow: 0 -1px rgba(0, 0, 0, 0.25);
  }
  
  
  
  
  
  
  
  
  
  
  
  
  .popoverPanel {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.4);
    border-radius: .1rem;
    font-size: .875rem;
    display: none;
    margin-right: 55px;
    width:500px;
    
  }
  
  .popoverPanel[data-show] {
    display: block;
  }
  
  .popoverHeader {
    padding: .5rem 1rem;
    margin: 0;
    font-size: 1rem;
    background-color: #f0f0f0;
    border-bottom: 1px solid rgba(0,0,0,.2);
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
  }
  
  .popoverBody {
    padding: 1rem 1rem;
    color: #212529;
    
  }
  
  .popoverArrow,
  .popoverArrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }
  
  .popoverArrow {
    visibility: hidden;
  }
  
  .popoverArrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
  
  .popoverPanel[data-popper-placement^='top'] > .popoverArrow {
    bottom: -5px;
  }
  
  .popoverPanel[data-popper-placement^='top'] > .popoverArrow::before {
    border-bottom: 1px solid rgba(0,0,0,.5);
    border-right: 1px solid rgba(0,0,0,.5);
  }
  
  .popoverPanel[data-popper-placement^='bottom'] > .popoverArrow {
    top: -5px;
  }
  
  .popoverPanel[data-popper-placement^='bottom'] > .popoverArrow::before {
    border-top: 1px solid rgba(0,0,0,.5);
    border-left: 1px solid rgba(0,0,0,.5);
  }
  
  .popoverPanel[data-popper-placement^='left'] > .popoverArrow {
    right: -5px;
  }
  
  .popoverPanel[data-popper-placement^='left'] > .popoverArrow::before {
    border-top: 1px solid rgba(0,0,0,.5);
    border-right: 1px solid rgba(0,0,0,.5);
  }
  
  .popoverPanel[data-popper-placement^='right'] > .popoverArrow {
    left: -5px;
  }
  
  .popoverPanel[data-popper-placement^='right'] > .popoverArrow::before {
    border-bottom: 1px solid rgba(0,0,0,.5);
    border-left: 1px solid rgba(0,0,0,.5);
  }
  </style>
  