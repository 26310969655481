<template>
    <div >
      <div :class="['modal', {'is-active': this.$store.state.settings.NewCalendarPageOpen}]">
        <div class="modal-background"></div>
        <div class="modal-card"> 
        <section  class="modal-card-body" style="border: 1px solid rgba(0, 0, 0, 0.8);">
            <div  style="
                text-align:right;
                padding-right:10px;
                top: 10px;
                bottom:0;
                position:sticky;
                overflow-y:scroll;
                overflow-x:hidden;
                z-index:98;
                ">
                <div>
                <img src="@/assets/images/close_icon.png" @click="close()" style="width:25px;">
            </div>
            </div>
  
        <div class="card reg-pad">
            <div> 
                <div style="
                        color:#0b3558;
                        font-size: x-large;
                        font-weight: 600;
                        text-align: left;
                        font-family: 'Roboto-Medium';
                        padding-right:30px;
                        padding-left:20px;
                        padding-top:0px;
                ">
                    Neuen Kalender anlegen
        </div>

              


        <!------------------- NAME OF CALENDAR START ----------------------->

        
<div style="
padding-left: 20px;
padding-right: 30px;
">
 
 <div 
            style="    
            user-select: none;
            padding-left: 0px;
            padding-top: 30px;
            padding-bottom: 15px;
            font-weight: 400;
            color:#0b3558;
            font-size:larger;
            font-weight: 600;
            text-align: left;
            font-family: 'Roboto-Light';
            "
          >Kalendername </div>
          <form>
            <div class="field">
              <div class="control">
                <input
                  v-model="form.kalendername"
                  class="input is-large"
                  placeholder="Kalendername"
                  autofocus=""
                  >
              </div>
            </div>
          </form>
 
     
         
      

  </div>
  

        <!--------------------NAME OF CALENDAR END ------------------------->
        
        



            <div 
            style="    
            user-select: none;
            padding-left: 25px;
            padding-top: 30px;
            
            color:#0b3558;
            font-size:larger;
            font-weight: 600;
            text-align: left;
            font-family: 'Roboto-Light';
            "
          >Kalenderfarbe2 </div>
          <div> </div>

          <div v-if="this.$store.state.settings.ShowDeleteButton">
               <myColorPicker></myColorPicker>
          </div> 

          <div v-if="!this.$store.state.settings.ShowDeleteButton">
            <myColorPicker></myColorPicker>
          </div> 

          <div class="grid-container2">
   <!-------------------------------------- RADIO BUTTONS START ------------------------------------->    

          <div style="
        padding-left: 20px;
        padding-top: 50px;
     
        ">
            <v-radio-group v-model="radios">
                <v-radio style="
                          color:black;
                         font-size:larger;
                         font-weight: 300;"
              value="private">
             <template v-slot:label>
          <div style="
                         font-size:larger;
                         font-weight: 300;">Privat</div>
        </template></v-radio>
                <v-radio 
                style="
                        color: black;
                         font-size:larger;
                         font-weight: 400;"
                value="public">
                <template v-slot:label>
          <div style="
                         font-size:larger;
                         font-weight: 300;">Öffentlich</div>
        </template></v-radio>
            </v-radio-group>
        </div>

<!-------------------------------------- RADIO BUTTONS END ------------------------------------->        

<!-------------------------------------- IMAGE UPLOAD START ------------------------------------->
<div  style="padding-top: 40px;
"><table>
    <tr>
        <td>
            <div class="ma-4">
            <div 
            style="    
            user-select: none;
            padding-left: 0px;
            
            
            color:#0b3558;
            font-size:larger;
            font-weight: 600;
            text-align: left;
            font-family: 'Roboto-Light';
            "
          >Kalender-Icon </div>
      <v-img
        style="margin-bottom: 10px;"
        class="center"
        width="100"
        :aspect-ratio="1"
        :src= calendarImage
        cover
      ></v-img>

    
      <v-btn
        class="ma-2,center"
      >
        <v-icon
          start
          icon="mdi-wrench"
          color="blue"
        ></v-icon>
        Ändern
      </v-btn>
      
    </div>
        </td>
        <td>
         
               
             
        </td>
    </tr>
</table></div>


        
    <!-------------------------------------- IMAGE UPLOAD END ------------------------------------->

</div>

    <!-------------------------------------- SAVE BUTTON START ------------------------------------->


              <div style="
                text-align: right;">
                <button
              @click="save()"
                    :disabled="isProcessing"
                    type="button"
                    class="mybutton1"
                    style="margin-top:30px;
                    margin-left:10px;
                    margin-top: 50px;
                    margin-right: 25px;
                    padding-left: 50px;
                    padding-right: 50px;
                    ">
                    Speichern
                  </button>
              </div>

                </div>
                <div
                style="margin-top:60px;
                       padding-bottom: 1px;"
                ></div>

    <!-------------------------------------- SAVE BUTTON END ------------------------------------->

                
    
  </div>

   
</section>
  </div>
  </div>
  </div>

</template>
  
<style scoped>

.grid-container {
    padding-left: 10px;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
} 

.grid-container2{
    padding-left: 10px;
    display: grid;
    grid-template-columns: auto auto ;
} 

.color-selector{
  width:51px;
  height:51px;
  border-radius: 25px;
  margin-top:13px;
  margin-left: 10px;
  margin-right: 8px;
  margin-bottom: 10px;
}

.color-selector2{
  width:51px;
  height:51px;
  border-radius: 25px;
  margin-top:13px;
  margin-left: 10px;
  margin-right: 8px;
  margin-bottom: 10px;
}

.top-grid{
    display: grid;
    grid-template-columns: 50% 50%;
    
    position:sticky;
    overflow-y:scroll;
    overflow-x:hidden;
    z-index:99;
}

.navbar-top {
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  position: sticky;
 margin-top:0px;
 height: 100px;
 
}

.navbar-top a {
  float: left;
  display: block;
  color: #f2f2f2;


}

.gridmenue {
    display: grid;
    grid-template-columns: 25% 50% 25% ;
}

#gridfooter {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-gap: 0px;
}

#gridfooter > div {
        font-size: 30px;
        padding-top:25px;
        color: #0b3558;
        background: #ffffff;
        text-align: left;
}

.grid-item-footer-text0{
  text-align: left;
  font-weight: 900;
  text-align: left;
  font-family: 'Roboto-Black';
  font-size: x-large;
}

.grid-item-footer-text1{
  text-align: left;
  font-weight: 900;
  text-align: left;
  font-family: 'Roboto-Black';
  font-size: medium;
}

.datenschutz-headline1{
  text-align: center;
  font-weight: 600;
  font-family: 'Roboto-Medium';
  font-size: xx-large;
  margin-top:20px;
  padding-bottom: 45px;
  color:#0b3558;
}

.datenschutz-headline2{
  text-align: left;
  font-weight: 600;
  font-family: 'Roboto-Medium';
  font-size: large;
  color:#0b3558;
}

.datenschutz-headline3{
  text-align: left;
  font-weight: 600;
  font-family: 'Roboto-Medium';
  font-size: xx-large;
  padding-top: 25px;
  padding-bottom: 15px;
  color:#0b3558;
  
}

.datenschutz-headline4{
  text-align: left;
  font-weight: 400;
  font-family: 'Roboto-Medium';
  font-size: large;
  color:#0b3558;
}

.datenschutz-headline5{
  text-align: left;
  font-weight: 300;
  font-family: 'Roboto-Medium';
  font-size: xx-large;
  padding-top: 45px;
  padding-bottom: 15px;
  color:#0b3558;
}
.datenschutz-headline6{
  text-align: left;
  font-weight: 700;
  font-family: 'Roboto-Medium';
  font-size: small;
  padding-bottom: 15px;
  color:#696969;
}

.datenschutz-headline7{
  text-align: left;
  font-weight: 600;
  font-family: 'Roboto-Medium';
  font-size: xx-large;
  color:#0b3558;
}

.datenschutz-text{
  text-align: left;
  font-weight: 600;
  font-family: 'Roboto-light';
  font-size: small;
  color:#0b3558;
}

.grid-item-footer-text2{
  text-align: left;
  font-weight: 400;
  font-family: 'Roboto-light';
  font-size: small;
}

@media (min-width: 100px){

  .reg-pad{
    margin-top:25px;
           margin-left: 15px;
        margin-right: 15px;
        padding-left:10px;
        padding-right:10px;
        padding-top:25px;
        margin-bottom: 50px;
  }
  .reg-text-1{
      color:#0b3558;
      font-size: small;
      font-weight: 500;
      text-align: left;
      font-family: 'Roboto-Light';
      padding-top:10px;
      padding-left:20px;
  }
  .reg-text-2{
      color:#0b3558;
      font-size: small;
      font-weight: 500;
      font-family: 'Roboto-Medium';
      text-align: left;
      padding-right:0px;
      padding-top:10px;
      padding-left:0px;       
  }
  .logo-top{
  height:50px;
}
  .video-text{
    color:#0b3558;
    font-size: large; 
    padding-top:0px; 
    padding-right: 1px; 
    font-weight: 400;text-align: left;
    font-family: 'Roboto-Black';
  }
  .header-top{
    color:#0b3558;
    font-size: x-large;
    font-weight: 500;
    text-align: center; 
    font-family: 'Roboto-Black';
  }

}

@media (min-width: 1200px){

  .reg-pad{
    margin-top:25px;
           margin-left: 45px;
        margin-right: 45px;
        padding-left:25px;
        padding-right:25px;
        padding-top:25px;
  }
  .reg-text-1{
      color:#0b3558;
      font-size: medium;
      font-weight: 500;
      text-align: left;
      font-family: 'Roboto-Light';
      padding-top:10px;
      padding-left:20px;
  }
  .reg-text-2{
      color:#0b3558;
      font-size: medium;
      font-weight: 500;
      font-family: 'Roboto-Medium';
      text-align: left;
      padding-right:0px;
      padding-top:10px;
      padding-left:0px;       
  }
  .logo-top{
  height:70px;
}
  .video-text{
    color:#0b3558;
    font-size: x-large; 
    padding-top:20px; 
    padding-right: 15px; 
    font-weight: 400;text-align: left;
    font-family: 'Roboto-Black'; 
  }

  .header-top{
    color:#0b3558;
    font-size: xx-large;
    font-weight: 500;
    text-align: center; 
    font-family: 'Roboto-Black';
  }

}



.center {
    display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.grid-container-movie {
    
    display: grid;
    grid-template-columns: 47% 53%;
    background-color: #ffffff;
    padding-left: 25px;
    padding-right: 20px;
    gap: 1rem;
  }
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 30px;
  padding-left: 0px;
  text-align: center;
}

.mybutton1 {
  background-color: #0099FF; 
  border: none;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 8px;
  padding-top: 7px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: 'Roboto-Black';
  font-size: large;
  font-weight: 500;
  cursor: pointer;
  border-radius: 23px;
}

.mybutton2 {
  background-color: #1e1e1e; 
  border: none;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 8px;
  padding-top: 7px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: 'Roboto-Black';
  font-size: small;
  font-weight: 300;
  cursor: pointer;
  border-radius: 23px;
}

</style>

<script>
    //import Cardone from "./cardone.vue"
    import { defineComponent, ref, computed } from 'vue'
    import { createPopper } from '@popperjs/core'
    import useAuth from '../composition/useAuth';
    import { useStore } from "vuex";
    import myColorPicker from './calendarColorPicker.vue';

    export default defineComponent ({
      data () {
        return {
          isOpen: true,
          //register start
          form: {
        kalendername: "",
        password: "",
        myColorPicker
      },
      
      message: null,
      error: null,
      emailSending: false,
      forgotPasswordemail: "",
      useAuth,
      showForgotPassword: false,
      showRegister: true,
      radios: "private",
      calendarColor: "#eeeeee",
      calendarImage: "https://firebasestorage.googleapis.com/v0/b/annucaldev.appspot.com/o/default%2Fannucallogo02.png?alt=media&token=d0438b30-8572-4f71-aebe-b9af83f8033c"
        }
      },
      computed:{
        content(){
            return this.$store.state.content.items;
        },
        content2(){
            return this.$store.state.loungecontentfirst.items;
        }
      },

   
  setup(props) {
    const button = ref(null);
    const popover = ref(null);
    const toggle = ref(false);
    const store = useStore();
    const { state } = store;
    
    
    const isProcessing = computed(() => state.user.auth.isProcessing);
    const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);
    const user = computed(() => state.user.data);

    const popperInstance = computed(() => {
      return createPopper(button.value, popover.value, {
        placement: props.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ],
        strategy: 'absolute'
      })
    })

    function changeEventColor(selectedColor){
      this.calendarColor = selectedColor;
    }

    const insertElement = (btn, tip) => {
      button.value = btn
      popover.value = tip
    }

    const handleShow = (e) => {
      if(button.value === null && popover.value === null) {
        insertElement(e.target, e.target.parentElement.querySelector('.popoverPanel'))
      }
    }

    const handleClick = (e) => {
      if(button.value === null && popover.value === null) {
        insertElement(e.target, e.target.parentElement.querySelector('.popoverPanel'))
      }
      popover.value.setAttribute('data-show', '')
      popperInstance.value.update()
      toggle.value = true
    }

    const handleHide = (e) => {
      
      if(button.value === null && popover.value === null) {
        insertElement(e.target, e.target.parentElement.querySelector('.popoverPanel'))
      }
      
      popover.value.removeAttribute('data-show');
      toggle.value = false
      
    }

    function close(){
      this.$store.state.settings.NewCalendarPageOpen = false
    }

    async function save(){
        let newCalendar = {
                //calendarId: this.$store.state.settings.currentCalendarId,
                color: this.calendarColor, 
                icon: this.calendarImage,
                name: this.form.kalendername,
                visible: true,
                private: this.radios,
                creatorUID: state.user.userID,
                creatorName: "Annucal",
              }
              await this.$store.dispatch("createCalendar", newCalendar);
              state.mycalendars.items.push(newCalendar)
              //console.log("DocId: " + docId )
              //tempdocid = docid.id
              this.$store.state.settings.NewCalendarPageOpen = false
    }

    function showstartpage(){
      this.$store.state.settings.introPageToShow = 1;
    }

    return {
      toggle,
      handleShow,
      handleClick,
      handleHide,
      close,
      save,
      showstartpage,
      isAuthenticated,
      user,
      isProcessing,
      changeEventColor
    }
  },
    })
  </script>