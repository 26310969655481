
<template>
 
  <div style="padding-top: 20px;">

    <div v-if="items.length == 0 && isAuthenticated  == true" style=" text-align: center;"> 
        <v-progress-circular
          indeterminate
          color="#f0f3f5"
        ></v-progress-circular>
    </div>


    <v-virtual-scroll 
      :items="items"
      height="520"
      item-height="48"
    > 
      <template v-slot:default="{ item,i }">

        <v-list-item 
        
        :key="i"
        :value="item"
        :color="item.color"
        :base-color="item.color"
        
        height="20px"
        variant="green"
        @click="setActiveCalendar(item.name, item.color, item.icon, item.calendarId, item.creatorUID)"
      >
  
      <template  v-slot:prepend>
        <v-checkbox-btn
        @change="showVisibleCalendars(item)"
        :model-value="item.visible"></v-checkbox-btn>
      <!-- <v-img class="mx-2"
          :width="30"
          aspect-ratio="1"
          cover
          :src=item.icon
          max-height="125"
          />-->
          <component 
                class="grid-item"
                :size="28" 
                :color=item.color 
                weight="light" 
                v-bind="props"
                :is=item.icon
            >
                </component>
      </template>
      
      
      <div class="grid-container">
          <div >
          <div class="center"  style="
          font-size: 13px;
          font-weight: 400;
          padding-left: 5px;
          padding-top: 7px;
          width:150px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-align: center left;
          color:{{item.color}}
          ">{{item.name}}
              </div>
          </div>
          <v-menu v-model="item.menuopen"  :location="end" :close-on-content-click="false" >
          <template v-slot:activator="{ props }" >
            
              
           <div style="
           padding-top: 8px;
           padding-left: 30px;">
            <PhDotsThreeVertical 
                 dark v-bind="props"
                 size="20"
                 color="rgb(123, 123, 123)"
                 weight="bold"
                 
               />
           </div>
                   
            
          </template>

          <div style="
            width: 450px;
            background-color: rgb(253, 253, 253);
          ">
            <CalendarMenu 
              @closeCalendarMenu="closeCalendarMenu"
              header="Kalender bearbeiten"
              prop_mode="edit"
              :prop_kalendername=item.name
              :prop_kalenderbeschreibung=item.description
              :prop_radios=item.private
              :prop_calendarColor=item.color
              :prop_icon=item.icon
              :prop_calendarId=item.calendarId
              :prop_docId=item.docId
              :prop_creatorUID=item.creatorUID
              :prop_externalCalendarLink=item.externalCalendarLink
               />
          </div>

      
          </v-menu>
              <div >
             
              </div>
              
          </div>
      </v-list-item>

        </template>

    </v-virtual-scroll>

  </div>
    
  </template>


  <script>
  
  import calenderCore from "../composition/calenderCore";
  import iCalCore from "../composition/iCalCore";
  import {useStore} from "vuex";
  import {computed } from 'vue'
  import {db} from "../db";
  import { doc, updateDoc} from "firebase/firestore";
  import CalendarMenu from "../components/CalendarMenu.vue";
  import useAuth from '@/composition/useAuth';
  
  
  export default {

    components:{
      CalendarMenu
    },


    data() {
      return {
        menu: false,
        
      },
      useAuth()
    },


    methods:{

       save(){
          this.menu = false
      },
       
     
    },

    
    
    setup(){
      const calenderCore1 = calenderCore();
      const iCalCore1 = iCalCore();
      const store = useStore();
      const { state } = store;
      
      const items = computed({
        get() {
          return state.mycalendars.items; 
        },  
      })

      function closeCalendarMenu(){
        
        for (let i2 = 0; i2 < items.value.length; i2++) {
          items.value[i2].menuopen = false
        }
        
      }
 
      function setActiveCalendar(calendar, color, icon, id, creatorUID){

          if(store.state.user.userID == creatorUID){
            state.settings.currentCalendarId = id;
            state.settings.currentCalendarName = calendar;
            state.settings.currentCalendarColor = color;
            state.settings.currentCalendarIcon = icon;
          }
    
      }



//https://npm.io/package/ical-date-parser
//https://www.kanzaki.com/docs/ical/rrule.html
//https://github.com/cwlsn/ics-to-json/blob/master/icsToJson.js

     

      

      async function  showVisibleCalendars(item){

        state.settings.currentVisibleCalendars = [];

        if(item.visible == true){

          item.visible = false;
          let newCalendar = {
            visible: false,
          };
          await updateDoc(doc(db, "users", store.state.user.userID, "myCalendars", item.docId), newCalendar);
          
        }else{

            if(item.externalCalendarLink != '' && item.externalCalendarLink != undefined){
              await iCalCore1.parseICALFile(item)
            }

            item.visible = true;
            let newCalendar = {
                visible: true,
            };
            await updateDoc(doc(db, "users", store.state.user.userID, "myCalendars", item.docId), newCalendar);
            }
            for (let i2 = 0; i2 < this.items.length; i2++) {

              if(this.items[i2].visible == true){
                state.settings.currentVisibleCalendars.push(this.items[i2].id)
              }
            } 
            calenderCore1.updateCalender();
      }

      return{
        
        setActiveCalendar,
        closeCalendarMenu,
        showVisibleCalendars,
        items,
        brandName: "Annucal - Plan ahead",
        menuItems: [],
        layerGridWidth: "100px auto",
        layerArrowImage: 'pfeilrechts3.png',
        buttonImage: 'newCalendarButton.png',
        isGridExpanded: false,
        checkbox1: true,
        checkbox2: false,
        dialogm1: '',
        dialog: false,
        radios: "private",
        items2: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' },
      ],
      locations: ['top', 'bottom', 'start', 'end', 'center'],
      location: 'end',
      firstSelection:  items.value[0],
      
      }
    }
  }
  </script>
  
  <style lang="scss">
    @import '../assets/styles/variables.scss';
    @import '~bulma/bulma.sass';
    @import '../assets/styles/main.scss';
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
  
    $navbar-item-hover-color: red;
  
  </style>
  
  <style scoped>


.center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
  .grid-container {
      display: grid;
      grid-template-columns: 130px 50px;
      grid-gap: 2;
  } 
  .grid-container2 {
    padding-left: 10px;
    padding-right: 10px;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
} 
  .color-selector2{
  width:25px;
  height:25px;
  border-radius: 25px;
  margin-top:10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0px;
}
  .list{
    background: #f6f8fc;
  }
  </style>
  



