<!-- Autocomplete -->


<template>
  <div>
    <v-form
    ref="form"
    >
    
      <v-text-field
          ref="form"
          style="padding-top: 8px;"
          density="compact"
          height="37px"
          v-model="search"
          clearable
          :label="$t('availableCalendarsSearch')"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          color="#6397ff"
      > 
      </v-text-field></v-form>

    
      <v-card v-if="searchResults.length > 0" variant="outlined" 
        >
        <div 
        class="scroll">

      <v-expansion-panels 
        class="searchbox"
        variant="flat"
      >
      
          <v-expansion-panel flat
              v-for="result in searchResults"
              :key="result.calendarId"
              color="#ffffff"
              bg-color="#f7f6f6"
              rounded="false"
          >
          

          <v-expansion-panel-title 
          style="
            user-select: none;
            
          "
          hide-actions: true
          expand-icon=""
          collapse-icon=""
          flat
          elevation-0
          
           class="grid-container-searchresult-title"
           disable-icon-rotate >

           <!--<v-img class="mx-2"
                  :width="40"
                  aspect-ratio="1"
                  cover
                  :src=result.icon
                  max-height="105"
                  style="margin-top: 5px;"
            />-->
            <component 
                        :size="32" 
                        :color=currentColor 
                        weight="light" 
                        max-height="40"
                        :is=result.icon
                        />
            <div 
            style="
            color:black;
            padding-left: 10px;
            font-size: 14px;
            "
            >{{ result.name }}</div>
          
          <div 
            style="
            color:black;
            padding-left: 15px;
            padding-right: 5px;
            font-size: small;
            text-align: right;
            
            "
            >{{ result.creatorName }}</div>

            <v-icon 
                color="blue"
                icon="mdi-check-circle"
                size="15px"
                >

                </v-icon>
             
           

          </v-expansion-panel-title>
      
            <v-expansion-panel-text
            elevation-0
            flat
            style="
            background-color: #eaf1ff;
            user-select: none;
            "
             disable-icon-rotate
             >
              <div class="grid-container-searchresult-text">
                <div class="small-Text">
                  <p style="font-size: 14px;">{{ result.description }}</p>
                  <p style="font-size: 12px;">
                    {{ Trenner(result.subscribers)  }} Abonnenten
                  </p>
                  <br>
                    <p style="font-size: 12px;">Erstellt von: {{result.creatorName}}</p>
                    <p style="font-size: 12px;">Veröffentlicht am: {{format(result.createdOn.seconds * 1000, 'dd. MMMM yyyy', { awareOfUnicodeTokens: true, locale: de})}}</p>
                    <p style="font-size: 12px;">Aktualisiert am: {{format(result.editedOn.seconds * 1000, 'dd. MMMM yyyy', { awareOfUnicodeTokens: true, locale: de})}}</p>
                    
                </div>
                <div 
                  style="
                  
                  float: right ;
                  
                  ">
                  <v-btn 
                    size="small"
                    :loading="loading"
                    style="
                    color: white;
                    "
                    @click="result.subscribed == true? unsubcribeToCalendar(result) : subcribeToCalendar(result)"
                    v-bind:style="
                      result.subscribed ? {'backgroundColor': '#d92341'} : {'backgroundColor': '#316bff'}"
                    >
                    {{result.subscribed ? 'entfernen' : 'hinzufügen'}}
                  </v-btn>
                </div>
                
              </div>
            </v-expansion-panel-text>

            

        </v-expansion-panel>

      </v-expansion-panels>
    </div>
    </v-card>
  </div>
  
</template>

<style scoped>

div.scroll {
        height: 550px;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: white;
      }

.v-expansion-panel {
flex: 1 0 100%;
max-width: 100%;
position: relative;
transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

border: red;
box-shadow: none !important;
}
.v-expansion-panel::before {
border-radius: inherit;
bottom: 0;
content: "";
left: 0;
position: absolute;
right: 0;
top: 0;
z-index: -1;
box-shadow: none !important;
}

.searchbox{
  background-color: rgb(253, 253, 253);
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  
}
.title{

}
.myselected{
background-color: rgb(66, 192, 20);
}

.grid-container-searchresult-title{
    display: grid;
    grid-template-columns: 10% 50% 39% 35px;
    height: 55px;
    text-align:  left;
    align-items:start;
    color: #6698fa;
    background-color: white;
    font-size: medium;
    font-weight: 300;
    align-items: center;
}

.grid-container-searchresult-text{
    display: grid;
    grid-template-columns: 75% 25%;
    grid-gap: 2;
    text-align:  left;
    padding-top: 10px;
    
    align-items:start;

}
.grid-container {
    display: grid;
    grid-template-columns: 50px 250px;
    grid-gap: 2;
    text-align:  left;
    
align-items:center;
  
} 


.small-Text{
font-size: smaller;
font-weight: 300;
}

</style>

<script setup>

import {db} from "../db";
import { computed, defineProps, ref } from 'vue';
import { useStore } from "vuex";
import { addDoc, collection, getDocs, updateDoc, deleteDoc, doc } from "firebase/firestore";
import format from 'date-fns/format'
import calenderCore from "../composition/calenderCore";

const props = defineProps({
  availableCalendars: {
      type: Array,
      required: true,
      default: () => []
  }
})

const loading = ref(false);
const store = useStore();
const { state } = store;
const calenderCore1 = calenderCore();

const mycalendars = computed({
        get() {
          return state.mycalendars.items;
        },
      })

const events = computed({
  get() {
    return state.events;
  },
})

async function unsubcribeToCalendar(result){

  loading.value = true;

  for (let index = 0; index < mycalendars.value.length; ++index){
    if(mycalendars.value[index].calendarId == result.docId){
      await deleteDoc(doc(db, "users", state.user.userID, "myCalendars", mycalendars.value[index].docId));
      mycalendars.value.splice(index, 1);
    }
  }

  for(let index2 = 0; index2 < events.value.items.length; ++index2){
    removeEvents(result.calendarId)
  }

   // Refresh Events Indexlist
   for (let index = 0; index < events.value.items.length; index++){
        events.value.items[index].index = index;
    }

  calenderCore1.updateCalender();
  
  result.subscribed = false;

  loading.value = false;

}

function removeEvents(docId){
  for(let index2 = 0; index2 < events.value.items.length; ++index2){
    if(events.value.items[index2].calendarId == docId){
      events.value.items.splice(index2,1);
    }
  }
}

async function subcribeToCalendar(result){

  loading.value = true;

  //---------- ADD CALENDAR TO MYCALENDARS IN FIRESTORE START ---------------------------------

  let newCalendar = {
            color: result.color, 
            icon: result.icon,
            name: result.name,
            visible: true,
            calendarId : result.docId,  
            description: result.description,
            creatorName: result.creatorName,
            createdOn: result.createdOn,
            editedOn: result.editedOn,
            creatorUID: result.creatorUID,
            calendarName: result.name
          }
  // Add Calendar
  const docRef = await addDoc(collection(collection(db, 'users'), state.user.userID, 'myCalendars'), newCalendar)
  // Add Calendar docId
  await updateDoc(doc(collection(db, 'users'), state.user.userID, 'myCalendars', docRef.id),{docId : docRef.id});


  //---------- ADD CALENDAR TO MYCALENDARS START ---------------------------------

  //---- ADD EVENTS FROM NEW CALENDAR START -----------------------------------------

  var snapshot = await getDocs(collection(db, "calendars", result.docId, "events"));
                var storedevents = snapshot.docs.map(doc => {
                    const data =  doc.data();
                    const id = doc.id;
                    return {id, ...data};
                })
  for (let index = 0; index < storedevents.length; ++index){
  let newdayEvent = {
    calendarId: storedevents[index].calendarId,
    id: storedevents[index].datefrom,
    eventidDB: storedevents[index].id,
    userid: storedevents[index].userid,
    color: storedevents[index].color, 
    title: storedevents[index].title,
    additionalInfos: storedevents[index].additionalInfos,
    datefrom: storedevents[index].datefrom,
    dateto: storedevents[index].dateto,
    datefromJson: storedevents[index].datefromJson,
    datetoJson: storedevents[index].datetoJson,
    icon: storedevents[index].icon,
    fromHour: storedevents[index].fromHour,
    fromMinute: storedevents[index].fromMinute,
    toHour: storedevents[index].toHour,
    toMinute: storedevents[index].toMinute,
    isFullDay: storedevents[index].isFullDay,
    calendarName: storedevents[index].calendarName   
    }

    events.value.items.push(newdayEvent)
    
  }

   // Refresh Events Indexlist
   for (let index = 0; index < events.value.items.length; index++){
        events.value.items[index].index = index;
    }
  
  //---- ADD EVENTS FROM NEW CALENDAR END -----------------------------------------


  //---------- ADD CALENDAR TO MYCALENDARS IN LOCAL LIST START ---------------------

  // Add Event Index to my calenders events
  let newEvents = []
  for (let eventIndex = 0; eventIndex < events.value.items.length; eventIndex++){
      if(result.calendarId == events.value.items[eventIndex].calendarId){
        newEvents.push(eventIndex) 
      }
  } 
    

  let newCalendar2 = {
            docId : docRef.id,
            color: result.color, 
            icon: result.icon,
            name: result.name,
            visible: true,
            calendarId : result.docId,  
            description: result.description,
            creatorName: result.creatorName,
            createdOn: result.createdOn,
            editedOn: result.editedOn,
            events: newEvents
          }

    mycalendars.value.push(newCalendar2)

     

    //for (let i2 = 0; i2 < mycalendars.value.length; i2++) {
    //    if(mycalendars.value[i2].visible == true){
    //      state.settings.currentVisibleCalendars.push(mycalendars.value[i2].docRef.id)
    //    }
   // }

  //---------- ADD CALENDAR TO MYCALENDARS IN LOCAL LIST END ---------------------

  calenderCore1.updateCalender();

  result.subscribed = true;

  loading.value = false;
  
}

const search = ref('')

const searchResults = computed(()=> {

  try{
      if(search.value === '' || search.value === null){
          return []
      }
      return props.availableCalendars.filter(item  => {

        const myArray = search.value.split(" ")
        let found = 0
        
        for (let i = 0; i < myArray.length; i++){
          if(item.name.toLowerCase().includes(myArray[i].toLowerCase()) || item.description.toLowerCase().includes(myArray[i].toLowerCase())){
            found++
          }
        }

        if(found == myArray.length){
          return item
        }


         // if(item.name.toLowerCase().includes(search.value.toLowerCase()) || item.description.toLowerCase().includes(search.value.toLowerCase())
         //   ){
         //     return item
         //   }
      })
  }catch(e){
    console.log(e)
    return 0
  }

})

var i = 0;
function Trenner(number) {
  try{
      // Info: Die '' sind zwei Hochkommas
      number = '' + number;
      if (number.length > 3) {
      var mod = number.length % 3;
      var output = (mod > 0 ? (number.substring(0,mod)) : '');
      for (i=0 ; i < Math.floor(number.length / 3); i++) {
      if ((mod == 0) && (i == 0))
      output += number.substring(mod+ 3 * i, mod + 3 * i + 3);
      else

      // hier wird das Trennzeichen festgelegt mit '.'
      output+= '.' + number.substring(mod + 3 * i, mod + 3 * i + 3);
}
return (output);
}
else return number;

  }catch(e){
    console.log(e)
    return null
  }

}
  



</script>



