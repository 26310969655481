
import { createStore } from "vuex";
import exchange from "./modules/exchange";
import user from "./modules/user";
import toast from "./modules/toast";
import calender from "./modules/calender";
import navbar from "./modules/navbar";
import events from "./modules/events";
import mycalendars from "./modules/mycalendars";
import settings from "./modules/settings";
import publicholidays from "./modules/publicholidays";
import schoolholidays from "./modules/schoolholidays";
import storedevents from "./modules/storedevents";
import storedmycalendars from "./modules/storedmycalendars";
import content from "./modules/launchcontent"
import dayevent from "./modules/dayevent";
import availablecalendars from "./modules/availablecalendars";
import storedavailablecalendars from "./modules/storedavailablecalendars";
import feiertage from "./modules/feiertage"; 
import timezones from "./modules/timezones";

export default createStore({
  modules: {
    exchange,
    user,
    toast,
    calender,
    navbar,
    events,
    mycalendars,
    settings,
    publicholidays,
    schoolholidays,
    storedevents,
    storedmycalendars,
    content,
    dayevent,
    availablecalendars,
    storedavailablecalendars,
    feiertage,
    timezones
  }
})